import { EventEmitter } from '../utils/events.js';

// Base WebSocket client
class WebSocketClient extends EventEmitter {
    constructor(options = {}) {
        super();
        
        this.options = options;
        this.ws = null;
        this.isConnected = false;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = options.reconnectAttempts || 5;
        this.reconnectDelay = options.reconnectDelay || 1000;
        this.debug = options.debug || true;
        this.authCheckInterval = null;
        
        if (this.debug) {
            console.log('[WebSocket] Client initialized with options:', options);
        }
    }

    log(...args) {
        if (this.debug) {
            console.log('[WebSocket]', ...args);
        }
    }

    async connect() {
        try {
            // Clear any existing auth check interval
            if (this.authCheckInterval) {
                clearInterval(this.authCheckInterval);
            }

            // Verify AWS authentication
            const isAuthenticated = await window.awsBundle.auth.isAuthenticated();
            if (!isAuthenticated) {
                throw new Error('User is not authenticated');
            }

            // Get current user
            const user = await window.awsBundle.auth.getCurrentUser();
            if (!user) {
                throw new Error('No authenticated user found');
            }

            // Get session token
            const token = await new Promise((resolve, reject) => {
                user.getSession((err, session) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    if (!session.isValid()) {
                        reject(new Error('Session is invalid'));
                        return;
                    }
                    resolve(session.getIdToken().getJwtToken());
                });
            });

            this.log('Got authentication token');

            if (this.ws) {
                this.log('Closing existing connection');
                this.ws.close();
            }

            // Add token to URL
            const url = new URL(this.options.url);
            url.searchParams.set('token', token);

            this.log('Connecting to', url.toString());
            this.ws = new WebSocket(url.toString());
            
            this.ws.onopen = () => {
                this.log('Connected successfully');
                this.isConnected = true;
                this.reconnectAttempts = 0;
                this.emit('open');

                // Set up authentication check interval
                this.authCheckInterval = setInterval(async () => {
                    try {
                        const isStillAuthenticated = await window.awsBundle.auth.isAuthenticated();
                        if (!isStillAuthenticated) {
                            this.log('Authentication expired');
                            this.ws.close();
                            window.location.href = '/auth-login.html?session=expired';
                        }
                    } catch (error) {
                        this.log('Auth check failed:', error);
                    }
                }, 60000); // Check every minute
            };

            this.ws.onclose = (event) => {
                this.log('Connection closed', event.code, event.reason);
                this.isConnected = false;
                this.emit('close', event);

                // Clear auth check interval
                if (this.authCheckInterval) {
                    clearInterval(this.authCheckInterval);
                }

                // Attempt reconnection if not a clean close
                if (!event.wasClean && this.reconnectAttempts < this.maxReconnectAttempts) {
                    this.reconnectAttempts++;
                    const delay = Math.min(this.reconnectDelay * Math.pow(1.5, this.reconnectAttempts - 1), 30000);
                    this.log(`Attempting reconnection in ${delay}ms (${this.reconnectAttempts}/${this.maxReconnectAttempts})`);
                    setTimeout(() => this.connect(), delay);
                }
            };

            this.ws.onerror = (error) => {
                this.log('Error occurred:', error);
                this.emit('error', error);
            };

            this.ws.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    this.log('Message received:', message);
                    this.emit('message', message);
                } catch (error) {
                    this.log('Error parsing message:', error);
                    this.emit('error', error);
                }
            };
        } catch (error) {
            this.log('Connection error:', error);
            throw error;
        }
    }

    send(type, data) {
        if (!this.isConnected) {
            throw new Error('WebSocket is not connected');
        }
        const message = JSON.stringify({ type, data });
        this.log('Sending message:', { type, data });
        this.ws.send(message);
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
        }
        if (this.authCheckInterval) {
            clearInterval(this.authCheckInterval);
        }
    }
}

// Command WebSocket client
export class CommandWebSocket extends WebSocketClient {
    constructor(options = {}) {
        super(options);
        this.commandCallbacks = new Map();
        this.log('Command WebSocket client initialized');
    }

    async executeCommand(command, args = {}) {
        this.log('Executing command:', command, 'with args:', args);
        return new Promise((resolve, reject) => {
            const messageId = Date.now().toString(36) + Math.random().toString(36).substr(2);
            
            const timeout = setTimeout(() => {
                this.commandCallbacks.delete(messageId);
                const error = new Error('Command timeout');
                this.log('Command timed out:', command, messageId);
                reject(error);
            }, 30000);

            this.commandCallbacks.set(messageId, { resolve, reject, timeout });

            try {
                this.send('command:execute', {
                    command,
                    args,
                    messageId
                });
                this.log('Command sent:', messageId);
            } catch (error) {
                clearTimeout(timeout);
                this.commandCallbacks.delete(messageId);
                this.log('Command send failed:', error);
                reject(error);
            }
        });
    }

    handleMessage(message) {
        this.log('Handling message:', message);
        if (message.type === 'command:response' && message.payload?.messageId) {
            const callback = this.commandCallbacks.get(message.payload.messageId);
            if (callback) {
                clearTimeout(callback.timeout);
                this.commandCallbacks.delete(message.payload.messageId);
                
                if (message.payload.error) {
                    this.log('Command failed:', message.payload.messageId, message.payload.error);
                    callback.reject(new Error(message.payload.error));
                } else {
                    this.log('Command succeeded:', message.payload.messageId);
                    callback.resolve(message.payload.result);
                }
            }
        }
    }
}

// Export for browser global
if (typeof window !== 'undefined') {
    window.CommandWebSocket = CommandWebSocket;
}

export default CommandWebSocket;